
import { defineComponent, ref } from "vue";
import ApiService from "@/core/services/ApiService";
import InactivationFilters from "./pending-requests/InactivationFilters.vue";
import { Modal } from "bootstrap";
import CheckModal from "@/components/modals/CheckModal.vue";
import { useStore } from "vuex";
import Swal from "sweetalert2/dist/sweetalert2.min.js";

export default defineComponent({
  name: "inactivations-table",
  components: {
    InactivationFilters,
    CheckModal
  },
  props: {
    widgetClasses: String,
    filterData: {
      type: Object,
      required: false,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      selectedDonorId: null,
      selectedDonorType: "",
      terminationReason: "",
      columns: [],
      entities: [],
      filters: {},
      count: 0,
      pagination: {
        page: 1,
        perPage: 10,
        total: 0,
      },
      loading: false,
      initialized: false,
      selectedAll: false,
    };
  },
  methods: {
    reloadData() {
      this.retrieveData();
      Swal.fire({
          title: "El donante se inactivó con éxito",
          icon: "success",
          showConfirmButton: false,
          timer: 1500,
        })
    },
    retrieveData() {
      this.initialized = true;
      this.loading = true;
      ApiService.query("donor_inactivations", {
        params: {
          filters: this.filters,
          perPage: this.pagination.perPage,
          page: this.pagination.page,
        },
      }).then((response) => {
        this.$emit("onRetrieve", response.data);
        this.loading = false;
        this.entities = response.data.data;
        this.columns = response.data.columns; 
        this.pagination.total = response.data.meta.total;
        this.count = response.data.meta.total;
      }).catch((error) => {
        console.error("Error fetching inactivation data:", error);
        this.loading = false;
      });
    },
    getEntityValue(entity, columnName) {
      return entity[columnName] ?? "N/A";
    },
    getColumnClass(column) {
      return column.sortable ? "sortable-column" : "";
    },
    getCustomColumnClass(columnName) {
      switch (columnName) {
      case "type":
        return "column-type";
      case "status":
        return "column-status";
      default:
        return "";
      }
    },
    getStatusText(status) {
      switch (status) {
        case "pending":
          return "Pendiente";
        case "approved":
          return "Aprobado";
        case "rejected":
          return "Rechazado";
        default:
          return "Desconocido";
      }
    },
    getStatusClass(status) {
      switch (status) {
        case "pending":
          return "warning";
        case "approved":
          return "success";
        case "rejected":
          return "danger";
        default:
          return "dark";
      }
    },
    translateStatus(status) {
    const statusTranslations = {
      pending: "Pendiente",
      approved: "Aprobado",
      rejected: "Rechazado",
    };
    return statusTranslations[status] || "Desconocido";
  },
    handleValidate(id) {
      console.log("Validando Baja con ID:", id);
    },
    updateFilters(newFilters) {
      this.filters = newFilters;
      this.retrieveData();
    },
    formatDate(date) {
      return new Date(date).toLocaleDateString();
    },
    terminateForm() {
      var modalito = new Modal(document.getElementById("check-modal"));
      modalito.show();
    },
    openCheckModal(donorId, terminationReason) {
      this.selectedDonorId = donorId;
      var modalito = new Modal(document.getElementById("check-modal"));
      modalito.show();
    },
  },
  mounted() {
    this.retrieveData();
  },
  setup() {
    const InactivationCheckModal = ref<null | HTMLElement>(null);

    const store = useStore();

    return  {
      InactivationCheckModal
    }

  }
});
