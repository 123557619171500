
  import { defineComponent, ref, watch } from "vue";
  import ApiService from "@/core/services/ApiService";
  
  export default defineComponent({
    name: "inactivation-filters",
    props: {
      applied_filters: Object,
      allowed_filters: {
        type: Array,
        required: false,
        default: () => {
          return [
            "type",
            "status",
            "creation_date",
            "firstName",
            "lastName",
            "phone",
            "termination_reason",
            "donor_id",
          ];
        },
      },
    },
    data() {
      return {
        terminationOptions: [{}] ,
        statusOptions: {
          pending: "Pendiente",
          approved: "Aprobado",
          rejected: "Rechazado"
        },
        filters: {
          type: "",
          creationDate: [],
          status: "",
          firstName: "",
          lastName: "",
          phone: "",
          terminationReason: "",
          donor_id: ""
        },
      };
    },
    emits: ["changed"],
    methods: {
      resetFilters() {
        this.filters = {
          type: "",
          creationDate: [],
          status: "",
          firstName: "",
          lastName: "",
          phone: "",
          terminationReason: "",
          donor_id: ""
        };
        this.dataChanged();
      },
      dataChanged() {
        const payload = {
          ...this.filters,
          creation_date: this.filters.creationDate?.length
            ? [this.filters.creationDate[0], this.filters.creationDate[1]]
            : undefined,
            termination_reason: this.filters.terminationReason || undefined,
        };
        this.$emit("changed", payload);
      },
    },
    mounted() {
      watch(this.filters, () => {
        this.dataChanged();
      });
  
      (this.filters as any) = this.applied_filters;
  
      ApiService.get("index", "termination_reasons").then((response) => {
        console.log(response.data);
        this.terminationOptions = Object.keys(response.data).map((key) => ({
          value: key,       // El ID (clave del objeto)
          label: response.data[key], // El nombre (valor del objeto)
        }));
      });
    },
  });
  